
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import IndexStudent from "@/views/class/student/Index.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "class-video-compose",
  props: {},
  components: {
    IndexStudent,
  },
  setup() {
    const route = useRoute();
    const classId = route.params["classId"];

    onMounted(() => {
      setCurrentPageBreadcrumbs("Khóa học", ["Đào tạo"]);
      MenuComponent.reinitialization();
    });

    return {
      classId,
    };
  },
});
